import React, { useContext } from 'react';
import { bool, func, shape } from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { productShape } from '../../helpers/pod-fulfillment-utils';
import { CheckNearByStore } from './CheckNearbyStore';
import { BopisOffIcon, LimitedStockIcon } from '../../helpers/icon-utils';
import { isFunction, getPickupStoreName } from '../../../components/helper/utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';
import { PodMessage } from '../PodMessage';

export const LimitedAvailability = ({
  product,
  onCheckNearbyStoresClick,
  customRender,
  bopisNotFulfillableOOS = false
}) => {
  const { channel } = useContext(ExperienceContext);
  const backupStoreName = useStore()?.storeName;
  const storeName = getPickupStoreName(product) || backupStoreName;

  const emptyComponent = () => {
    if (isFunction(customRender)) {
      return customRender({
        pickUpTemplate: PICKUP_TEMPLATES.EMPTY_STORE_MESSAGE,
        isEmptyComponent: true,
        icon: null,
        values: { product, storeName }
      });
    }
    return (<div className="sui-flex" />);
  };

  if (!(product && storeName)) {
    return emptyComponent();
  }

  const storeNameLink = (
    <CheckNearByStore
      product={product}
      onCheckNearbyStoresClick={onCheckNearbyStoresClick}
    />
  );

  if (isFunction(customRender)) {
    return customRender(
      {
        pickUpTemplate: PICKUP_TEMPLATES.LIMITED_AVAILABILITY,
        icon: LimitedStockIcon,
        values:
          { storeNameLink, storeName }
      });
  }

  return (
    <PodMessage
      messageIcon={bopisNotFulfillableOOS ? BopisOffIcon : LimitedStockIcon}
      messageType={bopisNotFulfillableOOS ? 'At Your Store' : 'Limited Stock'}
      isStoreNameAtEnd
    >
      <>
        {'For availability, visit '}
        {storeNameLink}
      </>
    </PodMessage>
  );
};

LimitedAvailability.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func,
  customRender: func,
  bopisNotFulfillableOOS: bool
};

LimitedAvailability.defaultProps = {
  onCheckNearbyStoresClick: () => { },
  customRender: null,
  bopisNotFulfillableOOS: false
};
