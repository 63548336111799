/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const renderChildren = (children) => {
  if (!children) {
    return <></>;
  }

  const elements = Array.isArray(children) ? children : [children];
  return <>{elements.map((child) => child)}</>;
};

export const PodTapper = ({
  children, fitWidth, zIndex, pointerNone, aPointer, buttonPointer, spanPointer
}) => {
  const PodTapperClassnames = [
    `${fitWidth ? 'sui-w-fit' : ''}`,
    `${zIndex ? 'sui-z-20' : ''}`,
    `${pointerNone ? 'sui-pointer-events-none' : ''}`,
    `${aPointer ? '[&_a]:sui-pointer-events-auto' : ''}`,
    `${buttonPointer ? '[&_button]:sui-pointer-events-auto' : ''}`,
    `${spanPointer ? '[&_span]:sui-pointer-events-auto' : ''}`
  ].filter(Boolean).join(' ').trim();

  return (
    <div
      className={PodTapperClassnames}
    >
      {renderChildren(children)}
    </div>
  );
};

/**
 * ?This Layout Piece seems to be too Generic? A flex container for subcomponents
 * @param children
 * @param columnAlign
 * @param noPadding - replace sui-p-2, with sui-p-0;
 * @param relative
 * @param margin
 * @param alignTop
 * @param alignEnd
 * @param gap - Spacing between product labels. As default, the minimum spacing must be of $spacing-2 = sui-gap-2.
 * @param fullHeight
 * @param display
 * @param grid
 * @param gridColumns
 * @param order
 * @param flex
 * @param marginTop
 * @param zIndex
 * @param fitWidth
 * @param aPointer
 * @param pointerNone
 * @param buttonPointer
 * @param padding
 * @returns {Element}
 * @constructor
 */
export const PodSection = ({
  children,
  columnAlign,
  noPadding,
  relative,
  margin,
  alignTop,
  alignEnd,
  gap,
  fullHeight,
  display,
  grid,
  gridColumns,
  order,
  flex,
  marginTop,
  zIndex,
  fitWidth,
  aPointer,
  pointerNone,
  buttonPointer,
  padding
}) => {
  const podSectionClasses = [
    `${display || 'sui-flex'}`,
    `${gridColumns || 'sui-grid-cols-1'}`,
    `${columnAlign ? 'sui-flex-col' : ''}`,
    `${noPadding ? 'sui-p-0' : 'sui-p-2'}`,
    `${relative ? 'sui-relative' : ''}`,
    `${margin ? 'sui-m-2' : ''}`,
    `${alignTop ? 'sui-justify-start' : ''}`,
    `${alignEnd ? 'sui-justify-end' : ''}`,
    `${gap || 'sui-gap-4'}`,
    `${fullHeight ? 'sui-h-full' : ''}`,
    `${grid || ''}`,
    `${order || ''}`,
    `${flex || ''}`,
    `${padding || ''}`,
    `${marginTop || ''}`,
    `${zIndex ? 'sui-z-20' : ''}`,
    `${fitWidth ? 'sui-w-fit' : ''}`,
    `${aPointer ? '[&_a]:sui-pointer-events-auto' : ''}`,
    `${pointerNone ? 'sui-pointer-events-none' : ''}`,
    `${buttonPointer ? '[&_button]:sui-pointer-events-auto' : ''}`,
  ].filter(Boolean).join(' ');

  return (
    <div className={podSectionClasses} data-testid="pod-section">
      {renderChildren(children)}
    </div>
  );
};

PodSection.propTypes = {
  columnAlign: PropTypes.bool,
  noPadding: PropTypes.bool,
  padding: PropTypes.string,
  relative: PropTypes.bool,
  margin: PropTypes.bool,
  alignTop: PropTypes.bool,
  alignEnd: PropTypes.bool,
  gridColumns: PropTypes.string,
  /* stencil display class, i.e. sui-block, sui-grid; default: sui-flex */
  display: PropTypes.string,
  grid: PropTypes.string,
  gap: PropTypes.string,
  order: PropTypes.string,
  flex: PropTypes.string,
  marginTop: PropTypes.string,
  zIndex: PropTypes.bool,
  fitWidth: PropTypes.bool,
  aPointer: PropTypes.bool,
  pointerNone: PropTypes.bool,
  buttonPointer: PropTypes.bool
};
PodSection.defaultProps = {
  columnAlign: false,
  noPadding: false,
  padding: false,
  relative: false,
  margin: false,
  alignTop: false,
  alignEnd: false,
  gridColumns: null,
  display: null,
  grid: null,
  gap: null,
  order: null,
  flex: null,
  marginTop: null,
  zIndex: false,
  fitWidth: false,
  aPointer: false,
  pointerNone: false,
  buttonPointer: false
};

export const PodHeader = ({ children, noPadding, minHeight, justifyBetween }) => {
  const podHeaderClassnames = [
    'sui-flex',
    `${justifyBetween ? 'sui-justify-between' : ''}`,
    `${noPadding ? 'sui-p-0' : 'sui-px-2 sui-pb-2'}`,
  ].filter(Boolean).join(' ');

  return (
    <div
      className={podHeaderClassnames}
      style={{ minHeight }}
      data-testid="pod-header"
    >
      {renderChildren(children)}
    </div>
  );
};

export const PodColumn = ({ children, minWidth, noPadding }) => {
  const podColumnClasses = [
    'sui-flex sui-flex-col sui-relative',
    `${noPadding ? 'sui-p-0' : 'sui-p-1'}`,
  ].join(' ');

  return (
    <div
      className={podColumnClasses}
      style={{ minWidth }}
      data-testid="pod-column"
    >
      {renderChildren(children)}
    </div>
  );
};

export const PodRow = ({ children, noPadding, rowReverse }) => {
  const podRowClasses = [
    'sui-flex sui-w-full sui-items-center',
    `${rowReverse ? 'sui-flex-row-reverse' : 'sui-flex-row'}`,
    `${noPadding ? 'sui-p-0' : 'sui-py-2'}`
  ].filter(Boolean).join(' ');

  return (
    <div
      className={podRowClasses}
      data-testid="pod-row"
    >
      {renderChildren(children)}
    </div>
  );
};

/**
 * Dynamic Pod wrapper that displays child elements in a Row on small screens,
 * and automatically shifts to display children in a Column on med. to lg. screens.
 * @param children - child elements to be displayed in row or column
 * @param minWidth - set minWidth style on top level wrapper element
 * @param gap - set gap style between elements. A default, the minimum spacing must be of 16px = sui-gap-4.
 * @param noPadding - replace sui-p-2, with sui-p-0;
 * @param grid - set grid style on top level wrapper element
 * @returns {Element}
 */
export const PodRowToColumn = ({
  children, minWidth, gap, noPadding, grid
}) => {
  const podRowClasses = [
    `${grid || 'sui-grid-cols-2 md:sui-grid-cols-1'}`,
    'sui-grid',
    `${gap || 'sui-gap-4'}`,
    `${noPadding ? 'sui-p-0' : 'sui-p-2'}`,
  ].filter(Boolean).join(' ');

  return (
    <div
      className={podRowClasses}
      style={{ minWidth }}
      data-testid="pod-column"
    >
      {renderChildren(children)}
    </div>
  );
};

export const PodFooter = ({ children, noPadding, margin }) => {
  const podFooterClasses = [
    `${noPadding || 'sui-px-2'}`,
    `${margin || 'sui-mt-auto'}`,
  ].filter(Boolean).join(' ');

  return (
    <div
      className={podFooterClasses}
      data-testid="pod-footer"
    >
      {renderChildren(children)}
    </div>
  );
};

// Note: position prop default is 'top-left'; but position can also be 'top-right'.
export const PodSticker = ({ children, position = 'top-left' }) => {
  const podStickerClasses = [
    'sui-absolute sui-flex sui-z-10',
    `${position === 'top-left' ? 'sui-left-0' : 'sui-right-0'}`
  ].filter(Boolean).join(' ');

  return (
    <div
      className={podStickerClasses}
      data-testid="pod-sticker"
    >
      {renderChildren(children)}
    </div>
  );
};

// TODO: reduce complexity of the prop values, while still controlling the spacing classes allowed to be used
// NOTE: if we allow any spacing values to be applied, do we need a PodSpacer class at all or do we just allow
// the users to make their own spacer div elements?
/**
 *
 * @param children
 * @param padding - sets same padding for all screen-sizes/breakpoints. see prop comment for more.
 * @param smlScreenPadding - sets padding for $screens-sm sizes only; see "breakpoints" in stencil design system.
 * @param minHeight - sets a static inline style of minHeight on the top level `PodSpacer` element
 * @param disable - disables the element, returns `null` instead.
 * @param fullwidth
 * @param display
 * @param margin
 * @returns {React.JSX.Element|null}
 */
export const PodSpacer = ({
  children, padding, smlScreenPadding, minHeight, disable, fullwidth, display, margin
}) => {
  if (disable) return null;

  const style = minHeight ? { minHeight } : {};

  const podSpacerClasses = [
    `${padding.includes('ALL') ? 'sui-p-1' : ''}`,
    `${padding.includes('LEFT') ? 'sui-pl-1' : ''}`,
    `${padding.includes('RIGHT') ? 'sui-pr-1' : ''}`,
    `${padding.includes('TOP') ? 'sui-pt-1' : ''}`,
    `${padding.includes('BOTTOM') ? 'sui-pb-1' : ''}`,

    `${padding.includes('ALL2') ? 'sui-p-2' : ''}`,
    `${padding.includes('L2') ? 'sui-pl-2' : ''}`,
    `${padding.includes('R2') ? 'sui-pr-2' : ''}`,
    `${padding.includes('T2') ? 'sui-pt-2' : ''}`,
    `${padding.includes('B2') ? 'sui-pb-2' : ''}`,

    `${padding.includes('ALL4') ? 'sui-p-4' : ''}`,
    `${padding.includes('L4') ? 'sui-pl-4' : ''}`,
    `${padding.includes('R4') ? 'sui-pr-4' : ''}`,
    `${padding.includes('T4') ? 'sui-pt-4' : ''}`,
    `${padding.includes('B4') ? 'sui-pb-4' : ''}`,

    `${smlScreenPadding.includes('ALL') ? 'sm:sui-p-1' : ''}`,
    `${smlScreenPadding.includes('LEFT') ? 'sm:sui-pl-1' : ''}`,
    `${smlScreenPadding.includes('RIGHT') ? 'sm:sui-pr-1' : ''}`,
    `${smlScreenPadding.includes('TOP') ? 'sm:sui-pt-1' : ''}`,
    `${smlScreenPadding.includes('BOTTOM') ? 'sm:sui-pb-1' : ''}`,

    `${smlScreenPadding.includes('ALL2') ? 'sm:sui-p-2' : ''}`,
    `${smlScreenPadding.includes('L2') ? 'sm:sui-pl-2' : ''}`,
    `${smlScreenPadding.includes('R2') ? 'sm:sui-pr-2' : ''}`,
    `${smlScreenPadding.includes('T2') ? 'sm:sui-pt-2' : ''}`,
    `${smlScreenPadding.includes('B2') ? 'sm:sui-pb-2' : ''}`,

    `${fullwidth ? 'sui-w-full' : ''}`,
    `${display || 'sui-block'}`,
    `${margin || ''}`,
  ].filter(Boolean).join(' ');

  return (
    <div
      className={podSpacerClasses}
      style={style}
      data-testid="pod-spacer"
    >
      {renderChildren(children)}
    </div>
  );
};

PodSpacer.propTypes = {
  /**
   * Padding numbers match spacing scale in stencil design system.
   * Examples: Left -> sui-pl-1, L2 -> sui-pl-2, ALL4 -> sui-p-4
   */
  padding: PropTypes.arrayOf(PropTypes.oneOf([
    'LEFT', 'RIGHT', 'TOP', 'BOTTOM', 'ALL',
    'L2', 'R2', 'T2', 'B2', 'ALL2',
    'L4', 'R4', 'T4', 'B4', 'ALL4'
  ])),
  smlScreenPadding: PropTypes.arrayOf(PropTypes.oneOf([
    'LEFT', 'RIGHT', 'TOP', 'BOTTOM', 'ALL',
    'L2', 'R2', 'T2', 'B2', 'ALL2',
  ])),
  // see Stencil Design System > Foundations > Spacing for correct values
  minHeight: PropTypes.string,
  margin: PropTypes.string
};
PodSpacer.defaultProps = {
  padding: [],
  smlScreenPadding: [],
  minHeight: null,
  margin: null
};
