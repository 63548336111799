/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { BundleFulfillmentMessageIcon } from '../../helpers/icon-utils';

export const BundleFulfillmentMessage = ({ fulfillmentBundleMessage }) => {
  return (
    <div data-component="BundleFulfillmentMessage" className="sui-flex">
      <div className="sui-mt-2 sui-pr-2">{BundleFulfillmentMessageIcon}</div>
      <Typography variant="body-sm">
        <div className="sui-pt-1">
          {fulfillmentBundleMessage}
        </div>
      </Typography>
    </div>
  );
};