import React, { useContext } from 'react';
import { shape, func } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  getNearbyStoreWithLimitedStock, formatDistance, productShape
} from '../../helpers/pod-fulfillment-utils';
import { CheckNearByStore } from './CheckNearbyStore';
import { LimitedStockIcon } from '../../helpers/icon-utils';
import { isMobile, isFunction } from '../../../components/helper/utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';
import { PodMessage } from '../PodMessage';

export const LimitedAvailabilityNearby = ({
  product,
  onCheckNearbyStoresClick,
  customRender
}) => {
  const { channel } = useContext(ExperienceContext);
  const nearbyStore = getNearbyStoreWithLimitedStock(product);

  const emptyComponent = () => {
    if (isFunction(customRender)) {
      return customRender({
        pickUpTemplate: PICKUP_TEMPLATES.EMPTY_STORE_MESSAGE,
        isEmptyComponent: true,
        icon: null,
        value: {}
      });
    }
    return (<div className="sui-flex" />);
  };

  if (!(product && nearbyStore)) {
    return emptyComponent();
  }

  const { storeName, distance } = nearbyStore;

  if (!(storeName && distance)) {
    return emptyComponent();
  }

  const storeNameLink = (
    <CheckNearByStore
      product={product}
      onCheckNearbyStoresClick={onCheckNearbyStoresClick}
      nearbyStoreName={storeName}
    />
  );

  if (isFunction(customRender)) {
    return customRender(
      {
        pickUpTemplate: PICKUP_TEMPLATES.LIMITED_AVAILABILITY_NEARBY,
        icon: LimitedStockIcon,
        values:
          { storeNameLink, storeName, distance: formatDistance(distance) }
      });
  }

  return (
    <PodMessage
      messageIcon={LimitedStockIcon}
      messageType="Pickup Nearby"
      isStoreNameAtEnd
    >
      <>
        {'Limited stock at '}
        {storeNameLink}
      </>
    </PodMessage>
  );
};

LimitedAvailabilityNearby.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func,
  customRender: func
};

LimitedAvailabilityNearby.defaultProps = {
  onCheckNearbyStoresClick: () => { },
  customRender: null
};
