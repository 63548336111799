import React, { useContext } from 'react';
import { shape, func, string, bool } from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { CheckNearByStore } from './CheckNearbyStore';
import { productShape } from '../../helpers/pod-fulfillment-utils';
import { UnavailableIcon } from '../../helpers/icon-utils';
import { isFunction, isMobile } from '../../../components/helper/utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';
import { PodMessage } from '../PodMessage';

export const NoStoreOption = ({
  storeMessage,
  enableOverlayLink,
  product,
  onCheckNearbyStoresClick,
  excludedState,
  customRender
}) => {
  const { channel } = useContext(ExperienceContext);
  const { storeName } = useStore();

  const overlayLink = (isMobile(channel) && !excludedState)
    ? <span className="sui-text-primary">{storeName}</span>
    : (
      <CheckNearByStore
        product={product}
        onCheckNearbyStoresClick={onCheckNearbyStoresClick}
        excludedState={excludedState}
      />
    );

  if (isFunction(customRender)) {
    return customRender(
      { pickUpTemplate: PICKUP_TEMPLATES.NO_STORE_OPTION,
        icon: UnavailableIcon,
        values:
        { message: storeMessage, overlayLink, enableOverlayLink, storeName } });
  }

  return (
    <PodMessage
      messageIcon={UnavailableIcon}
      messageType="Pickup"
    >
      <>
        <span className="sui-text-subtle">{storeMessage}</span>
        {enableOverlayLink && overlayLink}
      </>
    </PodMessage>
  );
};

NoStoreOption.propTypes = {
  enableOverlayLink: bool,
  onCheckNearbyStoresClick: func,
  product: shape({ productShape }).isRequired,
  storeMessage: string,
  excludedState: string,
  customRender: func
};

NoStoreOption.defaultProps = {
  enableOverlayLink: true,
  onCheckNearbyStoresClick: () => {},
  storeMessage: 'Unavailable',
  excludedState: '',
  customRender: null
};

NoStoreOption.displayName = 'NoStoreOption';
