import { SCHEDULED_DELIVERY_MESSAGE } from './constants';

export const formatDate = (date, isLongMonthName) => {
  if (!date) {
    return date;
  }
  const dateparts = date.split('-');
  const formattedDate = new Date(dateparts[0], dateparts[1] - 1, dateparts[2], 0, 0, 0);
  const longMonthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if (isLongMonthName) {
    return longMonthNames[formattedDate.getMonth()] + ' ' + formattedDate.getDate();
  }
  return shortMonthNames[formattedDate.getMonth()] + ' ' + formattedDate.getDate();
};

export const formatDateWithDayOfWeek = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return startDate;
  }
  // Single date
  if (startDate === endDate) {
    const dateparts = startDate.split('-');
    const formattedDate = new Date(dateparts[0], dateparts[1] - 1, dateparts[2], 0, 0, 0);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    return days[formattedDate.getDay()] + ', ' + months[formattedDate.getMonth()] + ' ' + formattedDate.getDate();
  }
  // Date range
  return formatDate(startDate, false) + ' - ' + formatDate(endDate, false);
};

export const getArrivalDate = (startDate, endDate) => {
  if (startDate === endDate) {
    return formatDate(startDate, true);
  }
  return formatDate(startDate, false) + ' - ' + formatDate(endDate, false);
};

export const getDeliveryDateAvailability = (deliveryDateAvailability) => {
  let availableDeliveryDate = '';
  if (deliveryDateAvailability && deliveryDateAvailability.toLowerCase()) {
    availableDeliveryDate = deliveryDateAvailability;
  }
  return availableDeliveryDate;
};

export const isDeliveryTimelineTodayOrTomorrow = (deliveryDateAvailability) => {
  let isDeliveryDateToday = false;
  if (deliveryDateAvailability
    && (deliveryDateAvailability.toLowerCase() === 'today'
      || deliveryDateAvailability.toLowerCase() === 'tomorrow')
  ) {
    isDeliveryDateToday = true;
  }
  return isDeliveryDateToday;
};

export const isDeliveryTimelineToday = (deliveryDateAvailability) => deliveryDateAvailability?.toLowerCase() === 'today';

export const getBulletPoints = (description) => {
  let bulletPoints = '';
  if (description) {
    bulletPoints = description.split('||');
  }
  return bulletPoints;
};

export const isBulletPoints = (shipMode) => {
  let hasBulletPoints = false;
  if (shipMode.hasBulletPoints && shipMode.bulletPoints.length > 0) {
    hasBulletPoints = true;
  }
  return hasBulletPoints;
};

export const hasShipModes = (data) => {
  let isShipModes = false;
  if (data
    && data.items
    && data.items.length > 0
    && data.items[0].deliveries
    && data.items[0].deliveries.length > 0
    && data.items[0].deliveries[0].shipModes
    && data.items[0].deliveries[0].shipModes.length > 0) {
    isShipModes = true;
  }
  return isShipModes;
};

export const checkAvailabilityResponse = (response, zipCode) => {
  let isCheckAvailabilityResponse = false;
  if (response
    && response.isDeliveryOptionResponse
    && !response.error
    && (zipCode !== response.zipCode)) {
    isCheckAvailabilityResponse = true;
  }
  return isCheckAvailabilityResponse;
};

export const getDeliveryZip = () => {
  let zipCode = '';
  if (typeof window !== 'undefined' && window?.THD_LOCALIZER_AUTO_INIT) {
    const zipDetails = window?.THD_LOCALIZER_AUTO_INIT?.Localizer?.getDeliveryZipcodeDetails();
    zipCode = zipDetails?.zipcode;
  }
  return zipCode;
};

export const setDeliveryZip = (deliveryZipCode) => {
  if (typeof window !== 'undefined' && window?.THD_LOCALIZER_AUTO_INIT?.Localizer && deliveryZipCode) {
    window.THD_LOCALIZER_AUTO_INIT.Localizer.updateDeliveryZipcodeDetails(deliveryZipCode, 'USER');
  }
};

export const getShipModeDetails = () => {
  // display delivery message
  return SCHEDULED_DELIVERY_MESSAGE;
};

export const getLongDescription = (longDesc) => {
  let shipmentModeLongDesc = '';
  let pattern = /Location: ?(.*)Scheduling: ?(.*)/i;
  let substrings = [];

  if (longDesc) {
    shipmentModeLongDesc = longDesc
      .split('||')
      .join(' ');
    substrings = pattern.exec(shipmentModeLongDesc);
  }
  if (substrings && substrings.length > 1) {
    return substrings[1] + ' ' + substrings[2];
  }
  return null;
};

export const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const isOutOfStock = (service, isBackordered = false) => {
  if (isBackordered) return false;
  const locations = service?.locations || [];
  if (locations.length) {
    return locations[0]?.inventory?.isOutOfStock;
  }
  return false;
};
