import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import { BasicPrice } from './subcomponents/BasicPrice';
import { StrikeThroughPrice } from './subcomponents/StrikeThroughPrice';
import { BasicPerItemPrice } from './subcomponents/BasicPerItemPrice';
import { StrikeThrougPerItemPrice } from './subcomponents/StrikeThroughPerItemPrice';
import { isBasicPerItemPrice, isStrikeThroughPerItemPrice, isStrikeThroughPrice, triggerNewRelicEvent } from '../../utils/utils';
import { SignInLink } from './subcomponents/signInLink/SignInLink';

let LOAD_ONCE = false
export const PriceMinimal = ({ skuModel, quantity, align, useNewMapPolicy }) => {
  const { isCustomerIdentified = false } = useThdCustomer() || {};
  const skuModelProduct = skuModel?._product;
  const skuModelPricing = skuModelProduct?.pricing ?? {};
  const skuModelPromotion = skuModelProduct?.pricing?.promotion ?? {};
  const mapPolicy = skuModelPricing?.mapDetail?.mapPolicy || '';
  const totalPrice = skuModelPricing?.cartItem?.total ?? 0.00;

  useEffect(() => {
    if(!LOAD_ONCE) {
      triggerNewRelicEvent({
        actionName: isCustomerIdentified ? 'PriceDisplayedAfterAuth' : 'MapPriceDisplayed',    
        layoutType: 'MinimalView',
        subExperience: 'PriceMinimal',
        customerStatus: isCustomerIdentified ? 'authenticated' : 'unauthenticated',     
        isCustomerIdentified // Pass the authentication status
      });
      LOAD_ONCE = true;
    }
  }, [isCustomerIdentified, useNewMapPolicy]);  

  const signIn = () => {
    return (
      useNewMapPolicy &&
      mapPolicy === "showSpecialPriceIfAuth" &&
      !isCustomerIdentified && <SignInLink layoutType="minimal" itemId={skuModelPricing?.itemId} align={align} />
    );
  };

  if (isBasicPerItemPrice(skuModel, quantity)) {
    const unitPrice = skuModelPricing.value
      ? skuModelPricing.value
      : skuModelPricing.original;
    return (
      <>
        <BasicPerItemPrice totalPrice={totalPrice} unitPrice={unitPrice} align={align} />
        {signIn()}
      </>
    );
  }
  if (isStrikeThroughPrice(skuModel, quantity)) {
    const unitPrice = skuModelPricing?.original ?? 0.00;
    const percentOffValue = skuModelPromotion?.percentageOff ?? 0;
    const message = skuModelPromotion?.message ?? 'no promotion avaiable';
    return (
      <div>
        <StrikeThroughPrice
          totalPrice={totalPrice}
          unitPrice={unitPrice}
          percentOffValue={percentOffValue}
          percentOffMessage={message}
          align={align}
        />
        {signIn()}
      </div>

    );
  }
  if (isStrikeThroughPerItemPrice(skuModel, quantity)) {
    const originalUnitPrice = skuModelPricing?.original ?? 0.00;
    const discountUnitPrice = skuModelPricing?.value ?? 0.00;
    const percentOffValue = skuModelPromotion?.percentageOff ?? 0;
    const percentOffMessage = skuModelPromotion?.message ?? 'no promotion aviable';
    return (
      <div>
        <StrikeThrougPerItemPrice
          totalPrice={totalPrice}
          originalUnitPrice={originalUnitPrice}
          discountUnitPrice={discountUnitPrice}
          percentOffValue={percentOffValue}
          percentOffMessage={percentOffMessage}
          align={align}
        />
        {signIn()}
      </div>
    );
  }
  return (
    <>
      <BasicPrice totalPrice={totalPrice} align={align} />
      {signIn()}
    </>
  );
};

PriceMinimal.propTypes = {
  /**
   * the transformed price data
   */
  skuModel: PropTypes.shape({
    _product: PropTypes.shape({
      pricing: PropTypes.shape({
        itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

        // unit price
        original: PropTypes.number.isRequired,

        // discount unit price
        value: PropTypes.number,

        // when true, display exchange pricing badge
        showExchangePricing: PropTypes.bool,

        // when true, display preferred pricing badge
        showPreferredPricingBadge: PropTypes.bool,

        // when true, display preferred pricing badge's tooltip
        showPreferredPricingBadgeToolTip: PropTypes.bool,

        cartItem: PropTypes.shape({

          // total price of this line-item
          total: PropTypes.number.isRequired,
        }),

        promotion: PropTypes.shape({
          // used to display %off message
          message: PropTypes.string,

          // % off when have discount
          percentageOff: PropTypes.string,
        }),
        dates: PropTypes.shape({
          // start date of discount
          start: PropTypes.string,

          // end date of discount
          end: PropTypes.string
        })
      }).isRequired
    }).isRequired,
  }).isRequired,
  quantity: PropTypes.number.isRequired,
  align: PropTypes.string,
  useNewMapPolicy: PropTypes.bool
};

PriceMinimal.defaultProps = {
  align: 'start',
  useNewMapPolicy: false
};

PriceMinimal.displayName = 'PriceMinimal';
