/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { Typography } from '@one-thd/sui-atomic-components';
import { getInventory } from '../helpers/pod-fulfillment-utils';
import { DELIVERY_TEMPLATES } from './templatesConstant';
import { isB2C } from '../../components/helper/utils';

const contactTemplate = (storeName) => {
  return (
    <div className="sui-flex">
      <Typography variant="body-sm">
        <div id="check-nearby-store" className="sui-pt-1 sui-overflow-hidden sui-text-ellipsis sui-max-w-[180px]">
          <span className="sui-text-warning">Contact {storeName || 'your local store'}</span>
        </div>
      </Typography>
    </div>
  );
};

const emptyShippingTemplate = ({ values } = {}) => {
  if (values?.isGCC) {
    return contactTemplate();
  }

  return (
    <div className="sui-flex">
      <Typography variant="body-sm">
        <div
          className="sui-text-subtle sui-pt-1 sui-overflow-hidden sui-text-ellipsis sui-max-w-[180px]"
        >Unavailable
        </div>
      </Typography>
    </div>
  );
};

export const getStockTemplate = (message) => {
  return (
    <div className="sui-flex">
      <Typography variant="body-sm">
        <div
          className={(message ? 'sui-text-primary' : 'sui-text-subtle')
            + ' sui-pt-1 sui-overflow-hidden sui-text-ellipsis sui-max-w-[180px]'}
        >{message || 'Unavailable'}
        </div>
      </Typography>
    </div>
  );
};

const formatMessage = (rawMessage, shippingThreshold, isUnavailableMessage) => {
  const putAsterik = shippingThreshold > 0;
  let textClassName = rawMessage.includes('Free') ? 'sui-text-success' : '';
  const isStandard = rawMessage.includes('Standard');
  let message = rawMessage;

  if (isUnavailableMessage) {
    textClassName = 'sui-text-subtle';
  }

  if (isStandard) {
    return <span className="sui-text-success"> Available</span>;
  }

  if (!rawMessage?.includes('No longer available') && !isStandard) {
    message = rawMessage ? rawMessage.split(' ')[0] : '';
    message += ' Delivery';
  }
  if (putAsterik) {
    return (<span className={textClassName}>{message}<sup>*</sup></span>);
  }
  return <span className={textClassName}>{message}</span>;
};

const defaultShippingTemplate = ({ values }) => {
  const isAppliance = values?.applianceQuantity > 0;
  const isStandard = values?.shippingMessage?.includes('Standard');
  const isQuantityGreaterThanZero = values?.displayQuantity > 0 || values?.applianceQuantity > 0;
  const isSeasonType = values?.product?.fulfillment?.seasonStatusEligible;
  const isB2CUser = isB2C(values?.experienceContextData?.customer);
  const isUnavailableMessage = values?.shippingMessage?.includes('Unavailable');

  if (isSeasonType && !isQuantityGreaterThanZero) {
    return getStockTemplate('In store only');
  }

  if ((!isQuantityGreaterThanZero && !isB2CUser) || isUnavailableMessage) {
    return emptyShippingTemplate();
  }

  return (
    <div className="sui-flex">
      <Typography variant="body-sm">

        <div
          className={(isQuantityGreaterThanZero ? values?.shippingCss : '')
            + ' sui-overflow-hidden sui-text-ellipsis sui-max-w-[180px]'}
        >
          {isQuantityGreaterThanZero && !isAppliance && !isB2CUser
            && (
              <>
                <span className="sui-text-success">{values?.displayQuantity}</span>
                {!isStandard && <span> | </span>}
              </>
            )}

          <span className={isQuantityGreaterThanZero ? values?.shippingCss : ''} />
          { formatMessage(values?.shippingMessage, values?.shippingThreshold, isUnavailableMessage) }
        </div>

      </Typography>
    </div>

  );
};

const renderTemplates = {
  [DELIVERY_TEMPLATES.DEFAULT_SHIPPING]: defaultShippingTemplate,
  [DELIVERY_TEMPLATES.EMPTY_SHIPPING_MESSAGE]: emptyShippingTemplate
};

export const renderDeliveryTemplates = ({ deliveryTemplate, icon, values }) => {
  return renderTemplates[deliveryTemplate] ? renderTemplates[deliveryTemplate](
    { deliveryTemplate, icon, values }) : contactTemplate();
};