import React, { useState, useEffect } from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { Clock } from '@one-thd/sui-icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getCondensedTimer, convertToHours, convertToMinutes } from './countdownHelpers';

// Promos end at midnight in Alaska timezone
// Only display countdown during the last 24 hours of the promo
export const PromoCountdownTimer = ({ end, align }) => {
  const [countDownMsg, setCountDownMsg] = useState('');
  const endDateInISO = new Date(end).toISOString();

  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const className = classNames('sui-w-full sui-flex sui-pt-4 sui-leading-none',
    { 'sui-justify-end sui-text-right': align === 'end' });

  const setAlaskaOffset = (date) => {
    let offSet;
    const alaska = date
      .toLocaleTimeString('en-US', { timeZone: 'America/Anchorage', timeZoneName: 'short' })
      .split(' ')[2];
    if (alaska === 'AKST') {
      offSet = '-09:00';
    } else {
      // AKDT
      offSet = '-08:00';
    }
    return offSet;
  };

  const getCountDownMessage = (diffInMilliseconds) => {
    const diffInHours = convertToHours(diffInMilliseconds);
    const diffInMins = convertToMinutes(diffInMilliseconds);
    if (diffInHours <= 0 && diffInMins <= 0) {
      return '';
    }
    return getCondensedTimer(diffInHours, diffInMins) + ' Left';
  };

  const getTimeToExpiration = (todayWithAlaskaOffset) => {
    const timeToExpiration = +todayWithAlaskaOffset - Date.now();
    return timeToExpiration;
  };

  useEffect(() => {
    const offSet = setAlaskaOffset(new Date());
    const alaskaEndOfDay = '22:59:59.999';
    const sanitizedEndDate = endDateInISO.split('T')[0];
    // Example: 2080-01-27T22:59:59.999-09:00
    const fullEndDateString = `${sanitizedEndDate}T${alaskaEndOfDay}${offSet}`;
    let todayWithAlaskaOffset = new Date(fullEndDateString);

    // Don't wait one second to display countdown timer
    let timeToExpiration = getTimeToExpiration(todayWithAlaskaOffset);
    if (timeToExpiration > 0 && timeToExpiration < millisecondsInADay) {
      setCountDownMsg(getCountDownMessage(timeToExpiration));
    }

    const interval = setInterval(() => {
      timeToExpiration = getTimeToExpiration(todayWithAlaskaOffset);

      if (timeToExpiration > 0 && timeToExpiration <= millisecondsInADay) {
        const message = getCountDownMessage(timeToExpiration);
        setCountDownMsg(message);
      } else if (timeToExpiration > millisecondsInADay) {
        // Nice to have: come back to this method when within 24 hours of end of promo
      } else {
        setCountDownMsg('');
        clearInterval(interval);
      }
      return () => {
        clearInterval(interval);
      };
    }, 1000);
  }, [endDateInISO, millisecondsInADay]);

  return (
    <div className={className}>
      <Typography weight="bold">
        <span className="sui-pr-2">
          {!!countDownMsg.length && <Clock color="primary" size="small" />}
        </span>
        <span className="sui-align-top">
          {countDownMsg}
        </span>
      </Typography>
    </div>
  );
};

PromoCountdownTimer.propTypes = {
  end: PropTypes.string,
  align: PropTypes.string
};

PromoCountdownTimer.defaultProps = {
  end: '',
  align: 'start'
};

PromoCountdownTimer.displayName = 'PromoCountdownTimer';
