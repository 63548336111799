import React from 'react';
import PropTypes from 'prop-types';
import {
  formatSellableQuantity
} from '../../helpers/pod-fulfillment-utils';

export const QuantityShipping = ({
  displayQuantity, shippingMessageStyle, shippingMessage, shippingThreshold, deliveryMessageToUse
}) => {

  let shippingType = 'Delivery';
  let shipMessage = shippingMessage;
  const shippingCss = shippingMessageStyle || 'sui-text-primary';

  const isStandardShipping = shippingMessage
    && typeof shippingMessage === 'string'
    && shippingMessage.toLowerCase() === 'standard';

  if (shippingThreshold) {
    shippingType = '';
    shipMessage = `free delivery with $${shippingThreshold} order`;
  }

  const formattedQty = formatSellableQuantity(displayQuantity);

  return (
    <div data-component="QuantityShipping" className={shippingCss}>
      <span className="sui-text-success">{formattedQty} </span>
      <span className="sui-text-primary">available</span>
      {(deliveryMessageToUse || !isStandardShipping) && (
        <span>
          <span className="sui-text-primary"> for</span>
          {!deliveryMessageToUse
            ? (<span className={shippingCss}>{` ${shipMessage} ${shippingType}`}</span>)
            : (
              <>
                {shippingMessageStyle === 'sui-text-success'
                  && <span className={shippingCss}>{` ${shipMessage}`}</span>}
                <span className={shippingCss}>{` ${deliveryMessageToUse}`}</span>
              </>
            )}
        </span>
      )}
    </div>
  );
};

QuantityShipping.propTypes = {
  displayQuantity: PropTypes.number,
  shippingMessageStyle: PropTypes.string,
  shippingMessage: PropTypes.string,
  shippingThreshold: PropTypes.number,
  deliveryMessageToUse: PropTypes.string
};

QuantityShipping.defaultProps = {
  displayQuantity: 0,
  shippingMessageStyle: null,
  shippingMessage: null,
  shippingThreshold: 0,
  deliveryMessageToUse: null
};
