import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import helpers from '../../helpers/price-helper';
import '../../styles/price-format.style.scss';

const PriceFormat = ({
  price,
  priceBadge,
  isSmallBadge,
  isSmallSize,
  hasStrikeThrough,
  basic,
  hideBadge,
  disableOuterSpacing
}) => {
  const priceObj = helpers.parsePriceValue(price);

  const priceClass = classNames('sui-flex sui-flex-row sui-leading-none');
  const strikeThroughPriceClass = classNames(
    'sui-border-t-[3px] sui-border-t-[#333] sui-border-solid sui-absolute', {
    'sui-w-16 sui-ml-2 sui-mt-2': isSmallSize,
    'sui-w-32 sui-mt-4': !isSmallSize
  });
  const symbolClass = classNames(
    'sui-font-display sui-leading-none', {
    'sui-text-xs': isSmallSize,
    'sui-text-3xl': !isSmallSize
  });
  const dollarClass = classNames(
    'sui-font-display sui-leading-none sui-px-[2px]', {
    'sui-text-4xl sui--translate-y-[0.35rem]': isSmallSize,
    'sui-text-9xl sui--translate-y-[0.5rem]': !isSmallSize
  });
  const centsClass = classNames(
    'sui-font-display sui-leading-none', {
    'sui-text-xs': isSmallSize,
    'sui-text-3xl': !isSmallSize
  });

  const badgeClass = classNames(
    "sui-mr-2 sui-inline-block sui-static sui-bg-no-repeat sui-align-middle sui-bottom-0 sui-bg-contain", {
    "sui-h-6 sui-w-6": isSmallBadge,
    "sui-h-12 sui-w-12": !isSmallBadge
  });

  const badgeImgPath = `https://assets.thdstatic.com/images/v1/Value-Pricing-${priceBadge}.svg`;

  if (!priceObj) return null;
  if (basic) {
    return (
      <div className="price">
        <span className={priceClass}>
          {`$${price}`}
        </span>
      </div>
    );
  }
  return (
    <div>
      <div className="sui-flex sui-flex-row sui-leading-none">
        {priceBadge && !hideBadge ? <div className={badgeClass}
            style={{ backgroundImage: `url(${badgeImgPath})` }}
          /> : null}
        {priceObj.dollars !== '0' && (
          <div className={priceClass}>
            <span className={symbolClass}>$</span>
            <span className={dollarClass}>{priceObj.dollars}</span>
            <span className="sui-sr-only">.</span>
            <span className={centsClass}>{priceObj.cents}</span>
            {hasStrikeThrough && <span className={strikeThroughPriceClass} />}
          </div>
        )}
        {priceObj.dollars === '0' && priceObj.cents !== '00' && (
          <div className={priceClass}>
            <span className={dollarClass}>{priceObj.cents}</span>
            <span className={symbolClass}>¢</span>
          </div>
        )}
        {priceObj.dollars === '0' && priceObj.cents === '00' && (
          <div className={priceClass}>
            <span className={symbolClass}>$</span>
            <span>{priceObj.dollars}</span>
            <span className="sui-sr-only">.</span>
            <span className={centsClass}>{priceObj.cents}</span>
          </div>
        )}
      </div>
    </div>
  );
};

PriceFormat.propTypes = {
  /**
  * To show the price of the product
  */
  price: PropTypes.string,
  /**
  * To show the price badge
  */
  priceBadge: PropTypes.string,
  /**
  * To confirm the badge size
  */
  isSmallBadge: PropTypes.bool,
  /**
  * To confirm the size
  */
  isSmallSize: PropTypes.bool,
  /**
  * To confirm if it has Strike
  */
  hasStrikeThrough: PropTypes.bool,
  /**
  * To the basic formate price
  */
  basic: PropTypes.bool,
  /**
  * To hide the Badge
  */
  hideBadge: PropTypes.bool,
  /**
   * Removes the left margin
   */
  disableOuterSpacing: PropTypes.bool
};

PriceFormat.defaultProps = {
  price: null,
  priceBadge: null,
  isSmallBadge: true,
  isSmallSize: true,
  hasStrikeThrough: false,
  basic: false,
  hideBadge: false,
  disableOuterSpacing: false
};

export default PriceFormat;
