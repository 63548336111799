import React, { useContext } from 'react';
import { shape, func } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  formatDistance,
  getSellableQuantityNearbyStore,
  getNearbyStoreInStock,
  productShape,
  formatSellableQuantity
} from '../../helpers/pod-fulfillment-utils';
import { CheckNearByStore } from './CheckNearbyStore';
import { InStockIcon } from '../../helpers/icon-utils';
import { isFunction } from '../../../components/helper/utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';
import { PodMessage } from '../PodMessage';

export const InStockNearby = ({
  product,
  onCheckNearbyStoresClick,
  customRender

}) => {
  const { channel } = useContext(ExperienceContext);
  const nearbyStore = getNearbyStoreInStock(product);

  const emptyComponent = () => {
    if (isFunction(customRender)) {
      return customRender({ pickUpTemplate: PICKUP_TEMPLATES.EMPTY_STORE_MESSAGE,
        isEmptyComponent: true,
        icon: null,
        value: {} });
    }
    return (<div className="sui-flex" />);
  };

  if (!(product && nearbyStore)) {
    return emptyComponent();
  }

  const { storeName, distance } = nearbyStore;

  if (!(distance && storeName)) {
    return emptyComponent();
  }

  const sellableQuantity = getSellableQuantityNearbyStore(product);
  if (!sellableQuantity) {
    return emptyComponent();
  }

  const formattedQty = formatSellableQuantity(sellableQuantity);

  const storeNameLink = (
    <CheckNearByStore
      product={product}
      onCheckNearbyStoresClick={onCheckNearbyStoresClick}
      nearbyStoreName={storeName}
    />
  );

  if (isFunction(customRender)) {
    return customRender(
      { pickUpTemplate: PICKUP_TEMPLATES.IN_STOCK_NEARBY,
        icon: InStockIcon,
        values:
        { formattedQty, storeName, storeNameLink, distance: formatDistance(distance) } });
  }

  return (
    <PodMessage
      messageIcon={InStockIcon}
      messageType="Pickup"
      isStoreNameAtEnd
    >
      <>
        <span className="sui-text-success">{`${formattedQty} nearby`}</span>
        <span className="sui-text-primary">{` (${formatDistance(distance)} mi)`}</span>
        <span className="sui-text-primary">{' at '}</span>
        {storeNameLink}
      </>
    </PodMessage>
  );
};

InStockNearby.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func,
  customRender: func
};

InStockNearby.defaultProps = {
  onCheckNearbyStoresClick: () => {},
  customRender: null
};
