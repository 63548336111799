import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, Typography, Popover } from '@one-thd/sui-atomic-components';

import { ExperienceContext } from '@thd-nucleus/experience-context';
import { QuantityShipping } from './QuantityShipping';
import { AvailableIcon, UnavailableIcon, InformationIcon } from '../../helpers/icon-utils';
import { getB2CDeliveryMessage, isB2B, isFunction, isMobile } from '../../../components/helper/utils';
import { DELIVERY_TEMPLATES } from '../../templates/templatesConstant';
import {
  getDeliveryMessageToUse,
  productShape
} from '../../helpers/pod-fulfillment-utils';
import { PodMessage } from '../PodMessage';

export const DefaultShipping = ({
  applianceDeliveryDate,
  shippingMessageStyle,
  iconType,
  shippingMessage,
  displayQuantity,
  applianceQuantity,
  shippingThreshold,
  excludedState,
  customRender,
  product,
  deliveryMessage,
  hasSameDayFilter,
  isAppliance
}) => {
  const experienceContextData = useContext(ExperienceContext);
  const { channel, customer } = experienceContextData || {};
  const iconSelector = iconType === 'close' ? UnavailableIcon : AvailableIcon;
  const tooltipMsg = 'Looks like we can\'t deliver this product to your area. '
    + 'Try changing your delivery ZIP to an address in a different state.';

  const shippingCss = classNames(shippingMessageStyle + ' sui-pt-1');

  const isCustomerB2B = isB2B(customer);

  const deliveryMessageToUse = deliveryMessage ? (getDeliveryMessageToUse[deliveryMessage] || null) : null;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const excludedStateTemplate = () => {
    return (
      <div data-component="excludedStateTooltip" className="sui-text-subtle">
        <span>{'Unavailable in '}</span>
        <span>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */}
          <Link
            underline="always"
            aria-describedby={id}
            onClick={handleClick}
            tabIndex="0"
          >
            <span className="sui-text-primary sui-text-sm sui-pl-1">
              <span>{excludedState}</span>
              <span className="sui-pl-1">{InformationIcon}</span>
            </span>
          </Link>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            placement="top"
            title="Excluded Ship State"
            style={{ zIndex: 900 }}
          >
            <Typography variant="body-sm">
              {tooltipMsg}
            </Typography>
          </Popover>
        </span>
      </div>
    );
  };

  const renderDeliveryMessageElement = (shippingMessageToUse) => (
    <>
      {shippingMessageStyle === 'sui-text-success'
        ? <span className={shippingCss}>{`${shippingMessageToUse} ${deliveryMessageToUse}`}</span>
        : <span className="sui-text-primary">{deliveryMessageToUse}</span>}
    </>
  );

  const renderApplianceMessageElement = (applianceDeliveryCss, shippingMessageToUse) => (
    <>
      <span className={applianceDeliveryCss}>
        {shippingMessageToUse === 'Free' ? shippingMessageToUse : 'Available'}
      </span>
      {' by '}
      <span className="sui-font-bold">{applianceDeliveryDate}</span>
    </>
  );

  const deliveryTemplate = () => {
    let shippingMessageToUse = shippingMessage;
    if (!isCustomerB2B && shippingMessageToUse === 'Standard') {
      shippingMessageToUse = 'Available';
    }
    const applianceDeliveryCss = classNames({
      'sui-text-success': shippingMessageToUse === 'Free'
    });
    return displayQuantity > 0
      ? (
        <QuantityShipping
          displayQuantity={displayQuantity}
          shippingMessageStyle={shippingMessageStyle}
          shippingMessage={shippingMessageToUse}
          shippingThreshold={shippingThreshold}
          deliveryMessageToUse={deliveryMessageToUse}
        />
      ) : (
        <div>
          {!isAppliance
            && (!deliveryMessageToUse
              ? (<span className={shippingCss}>{getB2CDeliveryMessage(shippingMessageToUse, !isCustomerB2B)}</span>)
              : (renderDeliveryMessageElement(shippingMessageToUse))
            )}
          {applianceDeliveryDate
            && renderApplianceMessageElement(applianceDeliveryCss, shippingMessageToUse)}
        </div>
      );
  };

  if (isFunction(customRender)) {
    return customRender({
      deliveryTemplate: DELIVERY_TEMPLATES.DEFAULT_SHIPPING,
      icon: iconSelector,
      values: {
        excludedState,
        shippingThreshold,
        displayQuantity,
        shippingMessage,
        shippingCss,
        applianceQuantity,
        product,
        experienceContextData
      }
    });
  }

  // double delivery message element
  if (hasSameDayFilter) {
    return (
      <div className="sui-flex sui-mt-2">
        <div className="sui-pl-6" />
        <Typography variant="body-sm">
          {excludedState ? excludedStateTemplate() : deliveryTemplate()}
        </Typography>
      </div>
    );
  }

  return (
    <PodMessage
      messageIcon={iconSelector}
      messageType="Delivery"
      isStoreNameAtEnd={excludedState}
    >
      <>
        {excludedState ? excludedStateTemplate() : deliveryTemplate()}
      </>
    </PodMessage>
  );
};

DefaultShipping.propTypes = {
  applianceDeliveryDate: PropTypes.string,
  shippingMessageStyle: PropTypes.string,
  shippingMessage: PropTypes.string,
  displayQuantity: PropTypes.number,
  applianceQuantity: PropTypes.number,
  iconType: PropTypes.string,
  shippingThreshold: PropTypes.number,
  excludedState: PropTypes.string,
  customRender: PropTypes.func,
  product: PropTypes.shape({ productShape }).isRequired,
  deliveryMessage: PropTypes.string,
  hasSameDayFilter: PropTypes.bool,
  isAppliance: PropTypes.bool
};

DefaultShipping.defaultProps = {
  applianceDeliveryDate: null,
  shippingMessageStyle: 'sui-text-primary',
  iconType: 'close',
  shippingMessage: 'Unavailable',
  displayQuantity: 0,
  applianceQuantity: 0,
  shippingThreshold: null,
  excludedState: null,
  customRender: null,
  deliveryMessage: null,
  hasSameDayFilter: false,
  isAppliance: false
};
