/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { MAJOR_APPLIANCE } from '../../components/constants';
import { PICKUP_TEMPLATES } from './templatesConstant';

const contactTemplate = (storeName) => {
  return (
    <div className="sui-flex">
      <Typography variant="body-sm">
        <div id="check-nearby-store" className="sui-pt-1 sui-overflow-hidden sui-text-ellipsis sui-max-w-[180px]">
          <span className="sui-text-warning">Contact {storeName || 'your local store'}</span>
        </div>
      </Typography>
    </div>
  );
};

const noStoreTemplates = ({ values } = {}) => {
  const message = values?.message?.includes('Not sold in stores') ? values?.message : 'Unavailable';
  const isAppliance = values?.product?.identifiers?.productType === MAJOR_APPLIANCE;
  const isStoreNameAvailable = values?.storeName;
  if (isAppliance || !isStoreNameAvailable) {
    return contactTemplate();
  }
  return (
    <div className="sui-flex">
      <Typography variant="body-sm">
        <div id="check-nearby-store" className="sui-pt-1 sui-overflow-hidden sui-text-ellipsis sui-max-w-[180px]">
          <span className={message === 'Unavailable' ? 'sui-text-subtle' : 'sui-text-primary'}>
            {message}
          </span>
        </div>
      </Typography>
    </div>
  );
};

const renderInStock = ({ values }) => {
  const isQuantityZero = values?.formattedQty === 0;
  if (isQuantityZero) {
    return noStoreTemplates();
  }

  return (
    <div className="sui-flex">
      <Typography variant="body-sm">
        <div id="check-nearby-store" className="sui-pt-1 sui-overflow-hidden sui-text-ellipsis sui-max-w-[180px]">
          <span className="sui-text-success">{`${values?.formattedQty} in stock`}</span>
          <span>{` | ${values?.storeName}`}</span>
        </div>
      </Typography>
    </div>
  );
};

const renderInStockNearBy = ({ values }) => {
  const isQuantityZero = values?.formattedQty === 0;
  if (isQuantityZero) {
    return noStoreTemplates();
  }

  return (
    <div className="sui-flex">
      <Typography variant="body-sm">
        <div id="check-nearby-store" className="sui-pt-1 sui-overflow-hidden sui-text-ellipsis sui-max-w-[180px]">
          <span className="sui-text-success">{`${values?.formattedQty} nearby`}</span>
          <span>{` | ${values?.storeName}`}</span>
        </div>
      </Typography>
    </div>
  );
};

const renderInStockClearance = ({ values }) => {
  const isQuantityZero = values?.formattedQty === 0;
  if (isQuantityZero) {
    return noStoreTemplates();
  }
  return (
    <div className="sui-flex">
      <Typography variant="body-sm">
        <div id="check-nearby-store" className="sui-pt-1 sui-overflow-hidden sui-text-ellipsis sui-max-w-[180px]">
          <span className="sui-text-success">{`${values?.formattedQty} in stock,`}</span>
          <span>{' not eligible for Pickup'}</span>
          <span>{` | ${values?.storeName}`}</span>
        </div>
      </Typography>
    </div>
  );
};

const renderLimitedStockClearance = ({ values }) => {
  return (
    <div className="sui-flex">
      <Typography variant="body-sm">
        <div>{`On Clearance at ${values?.storeNameLink}`}</div>
        <div className="sui-pt-1">
          <span>Visit store for availability</span>
        </div>

      </Typography>
    </div>
  );
};

const renderBoss = ({ values }) => {
  const isFree = values?.messagingSuccess?.trim() === 'Free';
  return (
    <div className="sui-flex">
      <Typography variant="body-sm">
        <div className="sui-pt-1">
          <span className="sui-text-success">
            {isFree && `${values?.messagingSuccess} ship to store`}
            {
              !isFree
             && (
               <>
                 <span className="sui-text-success">{values?.messagingSuccess}</span>
                 <span> | </span>
                 <span className="sui-text-success">Free ship to store</span>
               </>
             )
            }
          </span>
        </div>
      </Typography>
    </div>
  );
};

const limitedAvailabilityTemplate = ({ values }) => {
  return (
    <div className="sui-flex">
      <Typography variant="body-sm">
        <div id="check-nearby-store" className="sui-pt-1 sui-overflow-hidden sui-text-ellipsis sui-max-w-[180px]">
          <span className="sui-text-warning">Limited stock</span>
          <span>{` | ${values?.storeName}`}</span>
        </div>
      </Typography>
    </div>
  );
};

const bopisTemplate = ({ values }) => {
  const isQuantityZero = values?.formattedQty === 0;
  if (isQuantityZero) {
    return contactTemplate(values?.storeName || '');
  }

  return (
    <div className="sui-flex">
      <Typography variant="body-sm">
        <div id="check-nearby-store" className="sui-pt-1 sui-overflow-hidden sui-text-ellipsis sui-max-w-[180px]">
          <span className="sui-text-success">{`${values?.formattedQty} in stock`}</span>
          <span>{` | ${values?.storeName}`}</span>
        </div>
      </Typography>
    </div>

  );
};

const liveGoodsTemplate = ({ values }) => {
  const className = values?.message?.includes('Out of Season') ? 'sui-text-primary' : 'sui-text-success';

  return (
    <div className="sui-flex">
      <Typography variant="body-sm">
        <div className="sui-pt-1">
          <span className={className}>{values?.message}</span>
          {values?.storeName && (
            <div className="sui-inline">
              <span>{` | ${values?.storeName}`}</span>
            </div>
          )}
        </div>
      </Typography>
    </div>
  );
};

const renderTemplates = {
  [PICKUP_TEMPLATES.IN_STOCK]: renderInStock,
  [PICKUP_TEMPLATES.IN_STOCK_NEARBY]: renderInStockNearBy,
  [PICKUP_TEMPLATES.IN_STOCK_CLEARANCE]: renderInStockClearance,
  [PICKUP_TEMPLATES.LIMITED_STOCK_CLEARANCE]: renderLimitedStockClearance,
  [PICKUP_TEMPLATES.BOSS]: renderBoss,
  [PICKUP_TEMPLATES.NO_STORE_OPTION]: noStoreTemplates,
  [PICKUP_TEMPLATES.LIMITED_AVAILABILITY_NEARBY]: limitedAvailabilityTemplate,
  [PICKUP_TEMPLATES.LIMITED_AVAILABILITY]: limitedAvailabilityTemplate,
  [PICKUP_TEMPLATES.BOPIS_OFF_MESSAGE]: bopisTemplate,
  [PICKUP_TEMPLATES.EMPTY_STORE_MESSAGE]: noStoreTemplates,
  [PICKUP_TEMPLATES.LIVE_GOODS_POD]: liveGoodsTemplate
};

export const renderPickupTemplates = ({ pickUpTemplate, icon, values }) => {
  return renderTemplates[pickUpTemplate]
    ? renderTemplates[pickUpTemplate]({ pickUpTemplate, icon, values }) : contactTemplate();
};