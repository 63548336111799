import { PROD_DOMAIN } from "./constants";

const hasSpecialOffer = (skuModel) => {
  const specialOfferFound = skuModel._product.pricing?.value && skuModel._product.pricing?.value > 0;
  return specialOfferFound;
};

const hasPercentOff = (skuModel) => {
  return (
    skuModel._product.pricing?.promotion?.percentageOff
      && skuModel._product.pricing?.promotion?.percentageOff > 0
  );
};

export const isBasicPerItemPrice = (skuModel, quantity) => {
  return quantity > 1 && !hasPercentOff(skuModel);
};

export const isStrikeThroughPrice = (skuModel, quantity) => {
  const isStrikeThrough = quantity <= 1 && hasSpecialOffer(skuModel) && hasPercentOff(skuModel);
  return isStrikeThrough;
};

export const isStrikeThroughPerItemPrice = (skuModel, quantity) => {
  return quantity > 1 && hasSpecialOffer(skuModel) && hasPercentOff(skuModel);
};

export const LAYOUT_TYPES = Object.freeze({
  MINIMAL: 'minimal',
  DETAILED: 'detailed',
  SIMPLE: 'simple',
  PROJECT: 'project'
});

export const ANCHOR_STORES_STATUS_TYPE_CLEARANCE = 'CLEARANCE';

export const getNearByStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  if (locations.length > 1) {
    return locations.find((location) => !location.isAnchor);
  }
  return null;
};

export const getLocalStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  return locations.find((location) => location.isAnchor);
};

export const isQuantityAvailable = (fulfillment) => {
  const localStore = getLocalStore(fulfillment);
  return (localStore?.inventory?.quantity > 0 || localStore?.inventory?.isLimitedQuantity);
};

export const isItemOnClearance = (fulfillment) => {
  if (!fulfillment) {
    return false;
  }
  const pickupService = (fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');

  return !!((fulfillment?.anchorStoreStatusType?.toUpperCase() === ANCHOR_STORES_STATUS_TYPE_CLEARANCE
          && bopisFulfillment && isQuantityAvailable(bopisFulfillment)));
};

const getProtocol = () => {
  const protocolBase = window?.location?.protocol || '';
  if (protocolBase.length) {
    return protocolBase + '//';
  }
  return '';
};

export const getUrl = (path) => {
  let url = '';
  const host = window?.location?.host;
  if (typeof host === 'string' && (host.includes('hd-qa74') || host.includes('hd-qa71'))) {
    url = getProtocol() + host + path;
  } else {
    url = PROD_DOMAIN + path;
  }
  return url;
};

// Function to log NewRelic events
export const triggerNewRelicEvent = (props) => {
  try {
    if (typeof window !== 'undefined' && window?.newrelic) {
      window?.newrelic?.addPageAction(
        props.actionName,
        { 
          productSku: props?.itemId ? props?.itemId : "",
          callType: 'interaction',  
          experienceTag: 'PriceComponent',  
          subExperience: props?.subExperience ? props?.subExperience : "",  
          layoutType: props?.layoutType, 
          customerStatus: props?.customerStatus ? props?.customerStatus : "",          
          // Conditionally add pricing for authenticated users
          ...(props?.isCustomerIdentified 
            ? { pricingBefore: props?.pricing?.wasPrice || '', pricingAfter: props?.pricing?.nowPrice || '' } 
            : { pricing: props?.pricing?.nowPrice || '' }),
          // Error details if they exist
          ...(props?.error && { error: props?.error })  
        }
      );
    }
  } catch (err) {
    console.error(`NewRelic event logging failed for ${props.actionName}`, err);
  }
};

export const signIn = (itemId, layoutType) => {
  // Log the event when the user clicks the sign-in button
  triggerNewRelicEvent({
    actionName: 'SignInClick',
    itemId,
    layoutType,
    subExperience: 'SignIn',
  });
  // Sign-in logic
  try {
    setTimeout(() => {
      window?.top?.open(getUrl(`/auth/view/signin?redirect=${window?.top?.location?.pathname}`), '_self');
    }, 200)
  } catch (error) {
    // Log the event in case of an error during the sign-in process
    triggerNewRelicEvent({
      actionName: 'SignInError',
      itemId,
      layoutType,
      subExperience: 'SignIn',
      error: error.message 
    });
  }
};

