import React from 'react';
import PropTypes from 'prop-types';

export const PercentWithMessage = ({ percent, message }) => {
  let msg = message.endsWith(' ') ? message : message + ' ';
  msg += percent + '%';
  return (
    // eslint-disable-next-line max-len
    <span className="sui-font-regular sui-text-base sui-leading-none sui-text-success" data-automation-id="pricingScenariosPercentOffText">
      {msg}
    </span>
  );
};

PercentWithMessage.propTypes = {
  percent: PropTypes.string,
  message: PropTypes.string,
};

PercentWithMessage.defaultProps = {
  percent: '0',
  message: '',
};

PercentWithMessage.displayName = 'PercentWithMessage';
