import React from 'react';
import { string, bool, func } from 'prop-types';
import { isFunction } from '../../../components/helper/utils';
import { InStockIcon, UnavailableIcon } from '../../helpers/icon-utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';
import { PodMessage } from '../PodMessage';

const LiveGoodsPod = ({ storeName, seasonStatusEligible, customRender }) => {

  const iconSelector = seasonStatusEligible ? InStockIcon : UnavailableIcon;
  const pickupType = 'At Your Store';
  const seasonMessage = seasonStatusEligible ? 'In Season' : 'Out of Season';
  const seasonMessageStyle = seasonStatusEligible ? 'sui-text-success' : 'sui-text-subtle';
  const atMessageStyle = seasonStatusEligible ? 'sui-text-primary' : 'sui-text-subtle';

  if (isFunction(customRender)) {
    return customRender(
      {
        icon: iconSelector,
        pickUpTemplate: PICKUP_TEMPLATES.LIVE_GOODS_POD,
        values: { pickupType, storeName, message: seasonMessage }
      });
  }

  return (
    <PodMessage
      messageIcon={iconSelector}
      messageType={pickupType}
    >
      <>
        <span className={seasonMessageStyle}>{seasonMessage}</span>
        {storeName && (<> <span className={atMessageStyle}>at </span>{storeName}</>)}
      </>
    </PodMessage>
  );
};

LiveGoodsPod.propTypes = {
  storeName: string,
  seasonStatusEligible: bool,
  customRender: func,
};
LiveGoodsPod.defaultProps = {
  storeName: null,
  seasonStatusEligible: false,
  customRender: null,
};

export { LiveGoodsPod };
