import React from 'react';
import PropTypes from 'prop-types';
import { useConfigService } from '@thd-nucleus/experience-context';
import classNames from 'classnames';
import { isFunction } from '../../../components/helper/utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';
import {
  productShape
} from '../../helpers/pod-fulfillment-utils';

export const EmptyStoreMessage = ({ product, isGCC, customRender }) => {
  const isFfmPodHeightEnabled = useConfigService('fs:isFfmPodHeightEnabled') || false;
  const storeEmptyCss = classNames(
    { 'sui-h-10': isFfmPodHeightEnabled }
  );
  const defaultElements = () => {
    if (isGCC) {
      return <div data-testid="StoreEmptyGCC" className={storeEmptyCss} data-component="FulfillmentStoreEmptyGCC" />;
    }
    return <div data-testid="StoreEmpty" className={storeEmptyCss} data-component="FulfillmentStoreEmpty" />;
  };

  if (isFunction(customRender)) {
    return customRender(
      { pickUpTemplate: PICKUP_TEMPLATES.EMPTY_STORE_MESSAGE, icon: null, values: { isGCC, product } });
  }

  return (
    defaultElements()
  );
};

EmptyStoreMessage.propTypes = {
  isGCC: PropTypes.bool,
  customRender: PropTypes.func,
  product: PropTypes.shape({ productShape }),
};

EmptyStoreMessage.defaultProps = {
  isGCC: false,
  customRender: null
};
