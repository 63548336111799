import React from 'react';
import { DefaultShipping } from '../partials/shipping/DefaultShipping';
import { FULFILLMENT_METHODS } from '../../components/constants';
import {
  isApplianceDeliveryError,
  isApplianceDeliveryOutOfStock,
  isApplianceDeliveryUnavailable,
  getLiveGoodsStock
} from '../../components/helper/utils';
import {
  getShippingThreshold,
  isAppliance,
  isBackorderedWithDate,
  isBackordered,
  isBODFS,
  isDiscontinued,
  hasFreeShipping,
  isOutOfStockOnline,
  isSTH,
  shouldShowShippingThreshold,
  meetsApplianceDeliveryThreshold,
  applianceDeliveryThreshold,
  getApplianceEarliestDeliveryDate,
  getApplianceEarliestDeliveryDateFromSearch,
  getApplianceInventory,
  getDisplayableInventory,
  getExcludedStateSth,
  isBodfsWithQuantityAvailable,
  isBodfsWithQuantityUnknown,
  isSthOutOfStock,
  isItemGCC,
  getDeliveryService,
  isStateExcluded,
  getStateCode,
  getSTHFulfillment,
  getBODFSFulfillment
} from './pod-fulfillment-utils';
import { dataModel } from '../../components/dataModel';
import { EmptyShippingMessage } from '../partials/shipping/EmptyShippingMessage';

export const getShippingTemplate = ({
  availabilityData,
  product,
  customer,
  customRender,
  checkAvailabilityButton,
  isPuertoRicoRZip,
  enableApplianceDeliveryCharge,
  enableFreeDeliveryForExchange,
  isExchangeCustomer,
  hasSameDayFilter = false
}) => {
  const isGCC = isItemGCC(product);
  const getThresholdShipping = getShippingThreshold(product);

  if (!product || isGCC) {
    return () => (
      <EmptyShippingMessage
        isGCC={isGCC}
        customRender={customRender}
        product={product}
      />
    );
  }

  /* -----LiveGoods----- */
  if (getLiveGoodsStock(product?.fulfillment) === 0) {
    return () => (<EmptyShippingMessage customRender={customRender} product={product} />);
  }

  if (isDiscontinued(product)) {

    return () => (
      <DefaultShipping
        shippingMessage="No longer available"
        customRender={customRender}
        product={product}
        shippingMessageStyle="sui-text-subtle"
      />
    );
  }

  /* -----Appliance----- */
  if (isAppliance(product)) {
    const applianceFlatChargeApplies = enableApplianceDeliveryCharge && !isExchangeCustomer;
    const forceFreeShippingForExchange = isExchangeCustomer && enableFreeDeliveryForExchange;
    const earliestDeliveryDate = !isPuertoRicoRZip ? getApplianceEarliestDeliveryDateFromSearch(product) : null;

    let shippingMessage = checkAvailabilityButton;
    // FEATURE SWITCH OVVERIDE
    const meetsThreshold = !!forceFreeShippingForExchange || meetsApplianceDeliveryThreshold(product);

    if (applianceFlatChargeApplies) { // FEATURE SWITCH OVERRIDE
      shippingMessage = !earliestDeliveryDate ? checkAvailabilityButton : earliestDeliveryDate;
    } else if (earliestDeliveryDate || availabilityData) {
      shippingMessage = meetsThreshold
        ? 'Free'
        : '';
    }

    const shippingThreshold = meetsThreshold
      ? null
      : applianceDeliveryThreshold();

    if ((availabilityData || earliestDeliveryDate)) {
      const { deliveryAvailability } = availabilityData?.DeliveryAvailabilityResponse || {};
      const showUnavailable = isApplianceDeliveryOutOfStock(deliveryAvailability)
        || isApplianceDeliveryUnavailable(deliveryAvailability)
        || isApplianceDeliveryError(deliveryAvailability);

      if (showUnavailable) {
        return () => (
          <DefaultShipping
            iconType="close"
            shippingMessage="Unavailable"
            product={product}
            shippingThreshold={shippingThreshold}
            shippingMessageStyle="sui-text-subtle"
          />
        );
      }
      return () => (
        <DefaultShipping
          iconType="success"
          applianceDeliveryDate={getApplianceEarliestDeliveryDate(availabilityData) || earliestDeliveryDate}
          shippingMessage={applianceFlatChargeApplies ? 'Available' : shippingMessage}
          shippingMessageStyle={applianceFlatChargeApplies ? 'sui-text-primary' : 'sui-text-success'}
          shippingThreshold={shippingThreshold}
          product={product}
          isAppliance
        />
      );
    }

    return () => (
      <DefaultShipping
        iconType="success"
        shippingMessageStyle={applianceFlatChargeApplies ? 'sui-text-primary' : 'sui-text-success'}
        shippingMessage={shippingMessage}
        customRender={customRender}
        applianceQuantity={getApplianceInventory(product)}
        product={product}
        shippingThreshold={shippingThreshold}
        isAppliance={typeof shippingMessage !== 'object'}
      />
    );
  }

  if (!getDeliveryService(product)) {
    const zipcodeStateCode = getStateCode(null, 'sth');
    const excludedStateName = isStateExcluded(zipcodeStateCode, 'sth', product?.fulfillment);
    if (excludedStateName) {
      return () => (
        <DefaultShipping
          excludedState={excludedStateName}
          customRender={customRender}
          product={product}
        />
      );
    }
  }

  const bodfsFulfillment = getBODFSFulfillment(product);
  const sthFulfillment = getSTHFulfillment(product);

  /* -----STH Only----- */
  if (isSTH(product) && !isBODFS(product) && !hasSameDayFilter) {
    const excludedState = getExcludedStateSth(product);
    if (excludedState) {
      return () => (<DefaultShipping excludedState={excludedState} customRender={customRender} product={product} />);
    }
    if (isBackorderedWithDate(product) || isBackordered(product)) {
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessage="Standard"
          customRender={customRender}
          product={product}
        />
      );
    }
    if (hasFreeShipping(product)) {
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="sui-text-success"
          shippingMessage="Free"
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
          customRender={customRender}
          product={product}
          deliveryMessage={sthFulfillment?.deliveryMessage}
        />
      );
    }
    if (getThresholdShipping) {
      let shippingThreshold = shouldShowShippingThreshold(product, getThresholdShipping)
        ? getThresholdShipping : 0;
      let shippingMessage = shippingThreshold ? `Free with $${shippingThreshold} order` : 'Free';
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="sui-text-success"
          shippingMessage={shippingMessage}
          shippingThreshold={shippingThreshold}
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
          customRender={customRender}
          product={product}
          deliveryMessage={sthFulfillment?.deliveryMessage}
        />
      );
    }

    if (isSthOutOfStock(product)) {
      // eslint-disable-next-line max-len
      return () => (
        <DefaultShipping
          shippingMessage="Unavailable"
          customRender={customRender}
          product={product}
          hasSameDayFilter={hasSameDayFilter}
          shippingMessageStyle="sui-text-subtle"
        />
      );
    }

    return () => (
      <DefaultShipping
        iconType="success"
        shippingMessage="Standard"
        displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
        customRender={customRender}
        product={product}
        deliveryMessage={sthFulfillment?.deliveryMessage}
        hasSameDayFilter={hasSameDayFilter}
      />
    );
  }

  /* -----BODFS only----- */
  if (isBODFS(product) && !isSTH(product)) {
    // TODO: temporarily bypass same/next-day messaging for covid response
    /*
    if (isSameDayDelivery(product)) {
      return () => (
        <DefaultShipping
          alertType="success"
          shippingMessageStyle="sui-text-success"
          shippingMessage="Same-day"
          displayQuantity={displayQuantity}
        />
      );
    }
    if (isNextDayDelivery(product)) {
      return () => (
        <DefaultShipping
          alertType="success"
          shippingMessageStyle="sui-text-success"
          shippingMessage="Next-day"
          displayQuantity={displayQuantity}
        />
      );
    }
    */
    if (isBodfsWithQuantityUnknown(product) || !isBodfsWithQuantityAvailable(product)) {
      return () => (<DefaultShipping shippingMessage="Unavailable" customRender={customRender} product={product} />);
    }
    return () => (
      <DefaultShipping
        iconType="success"
        shippingMessage="Scheduled"
        displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.BODFS)}
        customRender={customRender}
        product={product}
        deliveryMessage={bodfsFulfillment?.deliveryMessage}
      />
    );
  }

  /* -----STH and BODFS----- */
  if (isBODFS(product) && isSTH(product)) {
    const excludedState = getExcludedStateSth(product);
    if (excludedState) {
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessage="Scheduled"
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.BODFS)}
          customRender={customRender}
          product={product}
          deliveryMessage={bodfsFulfillment?.deliveryMessage}
        />
      );
    }
    if (hasSameDayFilter) {
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessage="Scheduled"
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.BODFS)}
          customRender={customRender}
          product={product}
          deliveryMessage={bodfsFulfillment?.deliveryMessage}
          hasSameDayFilter={hasSameDayFilter}
        />
      );
    }
    if (hasFreeShipping(product)) {
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="sui-text-success"
          shippingMessage="Free"
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
          customRender={customRender}
          product={product}
          deliveryMessage={sthFulfillment?.deliveryMessage}
        />
      );
    }
    if (getThresholdShipping) {
      let shippingThreshold = shouldShowShippingThreshold(product, getThresholdShipping)
        ? getThresholdShipping : 0;
      let shippingMessage = shippingThreshold ? `Free with $${shippingThreshold} order` : 'Free';
      return () => (
        <DefaultShipping
          iconType="success"
          shippingMessageStyle="sui-text-success"
          shippingMessage={shippingMessage}
          shippingThreshold={shippingThreshold}
          displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
          customRender={customRender}
          product={product}
          deliveryMessage={sthFulfillment?.deliveryMessage}
        />
      );
    }
    return () => (
      <DefaultShipping
        iconType="success"
        shippingMessage="Standard"
        displayQuantity={getDisplayableInventory(product, customer, FULFILLMENT_METHODS.STH)}
        customRender={customRender}
        product={product}
        deliveryMessage={sthFulfillment?.deliveryMessage}
      />
    );
  }

  /* -----not STH and not BODFS means no delivery to customer directly----- */
  if (!isBODFS() && !isSTH()) {
    if (isDiscontinued(product)) {
      return () => (
        <EmptyShippingMessage
          customRender={customRender}
          product={product}
        />
      );
    }
    if (isOutOfStockOnline(product)) {
      return () => (
        <DefaultShipping
          shippingMessage="Unavailable"
          customRender={customRender}
          product={product}
          shippingMessageStyle="sui-text-subtle"
        />
      );
    }
    return () => (
      <DefaultShipping
        shippingMessage="Unavailable"
        customRender={customRender}
        product={product}
        shippingMessageStyle="sui-text-subtle"
      />
    );
  }

  return () => (<EmptyShippingMessage customRender={customRender} product={product} />);
};

getShippingTemplate.dataModel = dataModel;
