import React from 'react';
import { Skeleton, SkeletonLine } from '@one-thd/sui-atomic-components';

export const FulfillmentPodPlaceholder = () => (
  <Skeleton
    data-component="FulfillmentPodPlaceholder"
    component="span"
    grow
    disablePadding
  >
    <SkeletonLine variant="single" numberOfLines={2} />
  </Skeleton>
);
