import React from 'react';
import {
  InStorePickup,
  WarningFilled,
  Delivery,
  Close,
  InfoFilled,
  Cart
} from '@one-thd/sui-icons';

export const AvailableIcon = (
  <Delivery color="primary" size="small" />
);

export const BopisOffIcon = (
  <InStorePickup color="primary" size="small" />
);

export const InStockIcon = (
  <InStorePickup color="primary" size="small" />
);

export const LimitedStockIcon = (
  <WarningFilled color="warning" size="small" />
);

export const UnavailableIcon = (
  <Close color="primary" size="small" />
);

export const InformationIcon = (
  <InfoFilled color="info" size="small" />
);

export const BundleFulfillmentMessageIcon = (
  <Cart color="primary" size="small" />
);
