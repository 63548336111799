import React, { useContext } from 'react';
import { shape, func } from 'prop-types';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { CheckNearByStore } from './CheckNearbyStore';
import {
  getSellableQuantityYourStore,
  productShape,
  formatSellableQuantity
} from '../../helpers/pod-fulfillment-utils';
import { InStockIcon } from '../../helpers/icon-utils';
import { isFunction, getPickupStoreName } from '../../../components/helper/utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';
import { PodMessage } from '../PodMessage';

export const InStock = ({
  product,
  onCheckNearbyStoresClick,
  customRender
}) => {
  const { channel } = useContext(ExperienceContext);
  const backupStoreName = useStore()?.storeName;
  const storeName = getPickupStoreName(product) || backupStoreName;

  const emptyComponent = () => {
    if (isFunction(customRender)) {
      return customRender({ pickUpTemplate: PICKUP_TEMPLATES.EMPTY_STORE_MESSAGE,
        isEmptyComponent: true,
        icon: null,
        value: {} });
    }
    return (<div className="sui-flex" />);
  };

  if (!(product && storeName)) {
    return emptyComponent();
  }

  const sellableQuantity = getSellableQuantityYourStore(product);
  if (!sellableQuantity) {
    return emptyComponent();
  }

  const formattedQty = formatSellableQuantity(sellableQuantity);
  const storeNameLink = <CheckNearByStore product={product} onCheckNearbyStoresClick={onCheckNearbyStoresClick} />;

  if (isFunction(customRender)) {
    return customRender({ pickUpTemplate: PICKUP_TEMPLATES.IN_STOCK,
      icon: InStockIcon,
      values: { formattedQty, storeNameLink, storeName } });
  }

  return (
    <PodMessage
      messageIcon={InStockIcon}
      messageType="Pickup"
      isStoreNameAtEnd
    >
      <>
        <span className="sui-text-success">{`${formattedQty} in stock`}</span>
        <span className="sui-text-primary">{' at '}</span>
        {storeNameLink}
      </>
    </PodMessage>
  );

};

InStock.propTypes = {
  product: shape({ productShape }).isRequired,
  onCheckNearbyStoresClick: func,
  customRender: func
};

InStock.defaultProps = {
  onCheckNearbyStoresClick: () => {},
  customRender: null
};
