/* eslint-disable react/forbid-prop-types */
/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/price-stencil-override.scss';

export const ClearancePriceDetails = ({
  clearancePriceComponents,
  originalPriceComponents,
  isClearanceValueSameOrMoreOrginalPrice,
  dollarOffComponents,
  saveDollarOffText,
  message
}) => {

  return (
    <span className="sui-flex sui-mr-3">
      <div
        className={'sui-pr-1 sui-h3-bold sui-tracking-normal sui-normal-case'
              + ' sui-line-clamp-unset sui-flex sui-mt-[-8px]'}
      >
        {(clearancePriceComponents !== '')
              && (
                <>
                  <span
                    className="sui-ml-2px, sui-mr-2px sui-font-bold clearance-price-tootip-dollar"
                  >
                    $
                  </span>
                  <span className="sui-text-3xl sui-font-bold clearance-price">{clearancePriceComponents.dollars}</span>
                  <span
                    className="sui-text-[1.1rem] sui-ml-2px, sui-mr-2px sui-font-bold clearance-price-cents"
                  >
                    {clearancePriceComponents.cents}
                  </span>
                </>
              )}
      </div>
      <div>
        <div className="sui-text-subtle sui-pt-1 sui-h-5">
          {(originalPriceComponents !== '' && (message || !isClearanceValueSameOrMoreOrginalPrice))
            && (
              <span className="sui-text-[11px]">
                {message && (<span>{message}</span>)}
                {!isClearanceValueSameOrMoreOrginalPrice && (
                  <span className="sui-line-through">
                    ${originalPriceComponents.dollars}.{originalPriceComponents.cents}
                  </span>
                )}
              </span>
            )}
        </div>
        <div className="sui-text-success sui-text-[11px] sui-h-4">
          {(dollarOffComponents !== '') && saveDollarOffText}
        </div>
      </div>
    </span>
  );
};

ClearancePriceDetails.propTypes = {
  clearancePriceComponents: PropTypes.object.isRequired,
  originalPriceComponents: PropTypes.object.isRequired,
  isClearanceValueSameOrMoreOrginalPrice: PropTypes.bool,
  dollarOffComponents: PropTypes.object.isRequired,
  saveDollarOffText: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

ClearancePriceDetails.defaultProps = {
  isClearanceValueSameOrMoreOrginalPrice: false,
};