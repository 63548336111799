import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, bool, shape, useDataModel
} from '@thd-nucleus/data-sources';

export const ProductSponsored = ({ position, itemId, noBackground, noPadding }) => {
  const { data } = useDataModel('product', {
    variables: {
      itemId,
    },
  });

  const [sponsoredValues, setSponsoredValues] = useState({});
  const { info } = data?.product || {};
  const sponsoredLabelClass = 'sui-inline-block sui-rounded-full sui-px-2 sui-h-6';
  const sponsoredWrapperClass = noPadding ? '' : 'sui-pl-1';

  useEffect(() => {
    if (info) {
      setSponsoredValues({
        isSponsored: sponsoredValues?.isSponsored || info?.isSponsored,
        sponsoredBeacon: sponsoredValues?.sponsoredBeacon || info?.sponsoredBeacon,
        sponsoredMetadata: sponsoredValues?.sponsoredMetadata || info?.sponsoredMetadata
      });
    }
  }, [info?.isSponsored, info?.sponsoredBeacon, info?.sponsoredMetadata]);

  if (!data?.product) return null;
  const { isSponsored, sponsoredBeacon } = sponsoredValues;

  if (!isSponsored) return null;

  return (
    <div className={sponsoredWrapperClass}>
      <div
        className={`${sponsoredLabelClass} ${noBackground ? 'sui-bg-transparent' : 'sui-bg-subtle'}`}
        id={'plp_pod_sponsored_' + position}
        data-onview-beacon={sponsoredBeacon?.onViewBeacon}
        data-onclick-beacon={sponsoredBeacon?.onClickBeacon}
      >
        <span className="sui-text-xs">Sponsored</span>
      </div>
    </div>
  );
};

ProductSponsored.propTypes = {
  position: PropTypes.number.isRequired,
  itemId: PropTypes.string.isRequired,
  noBackground: PropTypes.bool,
  noPadding: PropTypes.bool,
};

ProductSponsored.defaultProps = {
  noBackground: false,
  noPadding: false
};

ProductSponsored.dataModel = {
  product: params({
    itemId: string().isRequired(),
  }).shape({
    itemId: string(),
    dataSources: string(),
    info: shape({
      isSponsored: bool(),
      sponsoredBeacon: shape({
        onClickBeacon: string(),
        onViewBeacon: string()
      }),
      sponsoredMetadata: shape({
        campaignId: string(),
        placementId: string(),
        slotId: string()
      }),
    }),
  }),
};

ProductSponsored.displayName = 'ProductSponsored';