import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { DeliveryContentDrawer } from './drawer/DeliveryContentDrawer';
import DeliveryContent from './DeliveryContent';
import './delivery-options.style.scss';
import { track } from '../analytics';
import { dataModel } from './dataModel';

const DeliveryOptions = ({
  storeId, zipCode, itemId, quantity, children, visible, useDrawer, channelDemo, useLoader, useError,
  disableSTH, showTodayOrTomorrow = null,
}) => {

  const [linkClicked, setLinkClicked] = useState(visible);

  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setLinkClicked(true);

    track('click', {
      origin: 'delivery-options',
      eventName: 'shipping options',
      primaryCategory: 'tooltip'
    });
  };

  const getDeliveryOptionsComponent = () => {
    if (useDrawer) {
      return (
        <>
          <button type="button" data-automation-id="deliveryDetailsbtn" className="u__default-link" onClick={handleOnClick}>
            {children}
          </button>
          <DeliveryContentDrawer
            zipCode={zipCode}
            storeId={storeId}
            itemId={itemId}
            quantity={quantity}
            isQuickViewOpen={linkClicked}
            setIsQuickViewOpen={setLinkClicked}
            channelDemo={channelDemo}
            useLoader={useLoader}
            useError={useError}
            showTodayOrTomorrow={showTodayOrTomorrow}
            disableSTH={disableSTH}
          />
        </>
      );
    }

    return (
      <Tooltip
        channel="desktop"
        closeButton
        visible={visible}
        content={linkClicked ? (
          <DeliveryContent
            zipCode={zipCode}
            storeId={storeId}
            itemId={itemId}
            quantity={quantity}
          />
        ) : ('')}
        maxWidth={320}
        placement="bottom"
      >
        <button
          type="button"
          data-testid="get-deliveryOptions"
          className="u__default-link"
          onClick={handleOnClick}
        >
          {children}
        </button>
      </Tooltip>
    );
  };

  return (
    <div id="delivery-options-fulfillment-drawer" data-component="DeliveryOptions">
      {getDeliveryOptionsComponent()}
    </div>
  );
};

DeliveryOptions.displayName = 'DeliveryOptions';

DeliveryOptions.propTypes = {
  itemId: PropTypes.string.isRequired,
  storeId: PropTypes.string,
  zipCode: PropTypes.string,
  children: PropTypes.node,
  visible: PropTypes.bool,
  quantity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  useDrawer: PropTypes.bool,
  channelDemo: PropTypes.string,
  useLoader: PropTypes.bool,
  useError: PropTypes.bool,
  showTodayOrTomorrow: PropTypes.func,
  disableSTH: PropTypes.bool
};

DeliveryOptions.defaultProps = {
  children: 'Delivery Options',
  storeId: '',
  zipCode: '',
  quantity: 1,
  visible: false,
  useDrawer: false,
  channelDemo: null,
  useLoader: false,
  useError: false,
  showTodayOrTomorrow: null,
  disableSTH: false,
};

DeliveryOptions.dataModel = dataModel;

export default DeliveryOptions;
