/* eslint-disable tailwindcss/no-arbitrary-value */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';

export const PodMessage = ({
  messageIcon,
  messageType = 'Pickup',
  isStoreNameAtEnd,
  children
}) => {
  return (
    <div
      data-component={`podMessage${messageType}`}
      className={`sui-flex ${messageType === 'Delivery' ? 'sui-mt-2' : ''}`}
    >
      <div className="sui-mt-2 sui-pr-2">{messageIcon}</div>
      <Typography variant="body-sm">
        <div className="sui-pt-1">
          <span className="sui-font-bold">{messageType}</span>
          <div className={`sui-text-sm ${isStoreNameAtEnd ? 'sui-line-clamp-1 sui-break-all' : ''}`}>
            {children}
          </div>
        </div>
      </Typography>
    </div>
  );
};

PodMessage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  messageIcon: PropTypes.any.isRequired,
  messageType: PropTypes.string,
  isStoreNameAtEnd: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

PodMessage.defaultProps = {
  messageType: 'Pickup',
  children: null,
  isStoreNameAtEnd: false
};
