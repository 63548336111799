/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { useEcoRebates } from '../../hooks/useEcoRebates';
import { Link } from '@one-thd/sui-atomic-components';

const triggerRebateAnalytics = () => {
  window.LIFE_CYCLE_EVENT_BUS.trigger('price.rebates_click', {});
};

export const Rebates = ({
  itemId,
  large,
  optionalText,
  rebateClasses,
  zipCode,
}) => {
  const [uniqueId, setUniqueId] = useState(null);
  useEffect(() => {
    // only generate uniqueId on client-side to prevent server-client mismatches
    setUniqueId(uuid());
  }, [setUniqueId]);

  useEcoRebates({
    itemId,
    uniqueId,
    large,
    optionalText,
    rebateClasses,
    zipCode,
  });

  const rebatesWrapperClasses = classNames({
    'sui-min-h-[1.25rem] sui-text-sm': !large,
    'sui-min-h-[1.75rem] sui-text-lg': large,
  })

  return (
    <Link color='primary' underline='always' className={rebatesWrapperClasses} onClick={triggerRebateAnalytics}>
      <span id={uniqueId}></span>
    </Link>
  );
}

Rebates.propTypes = {
  /**
 * To show the Item ID
 */
  itemId: PropTypes.string,
  /**
 * To show the Zipcode
 */
  zipCode: PropTypes.string,
  /**
 * To show the optional text
 */
  optionalText: PropTypes.string,
  /**
 * To show the rebate classes scripts
 */
  rebateClasses: PropTypes.string,
  /**
   * To show set _ecr.uiContext based on if price is large
   */
  large: PropTypes.bool
};

Rebates.defaultProps = {
  itemId: null,
  optionalText: null,
  rebateClasses: null,
  zipCode: null,
  large: null
};
