export const DRAWER_HEADER = 'Delivery Details';
export const DRAWER_FOOTER = 'Choose your delivery option in Checkout';
export const STH_DELIVERY = 'Estimated Delivery:';
export const BODFS_DELIVERY = 'Get as soon as';
export const BODFS_DESC = 'Scheduled Delivery';
export const SHIP_TO_HOME = 'sth';
export const BUY_ONLINE_DELIVER_FROM_STORE = 'bodfs';
export const EXPRESS_DELIVERY = 'express delivery';
export const ERROR_MESSAGE = 'We’re experiencing technical difficulties. Please try again shortly.';
export const SIX_HOUR_CAR_BODFS_MESSAGE = 'Get it within 6 hours.';
export const CHOOSE_BODFS_MESSAGE = 'Choose Scheduled Delivery in checkout.';
export const SCHEDULED_DELIVERY_MESSAGE = 'Schedule a day and time window for your order to be delivered '
    + 'to an easily accessible area outside your home. Upgrade options available.';
