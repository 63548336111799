import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from '../../../components/helper/utils';
import { InStockIcon } from '../../helpers/icon-utils';
import { PICKUP_TEMPLATES } from '../../templates/templatesConstant';
import {
  formatSellableQuantity
} from '../../helpers/pod-fulfillment-utils';
import { PodMessage } from '../PodMessage';

export const BOSS = ({
  displayQuantity,
  customRender
}) => {

  let messagingSuccess = 'Free ';
  let messagingPrimary = 'Ship to Store';
  let messagingFree = '';
  let messagingBOSS = '';

  if (displayQuantity > 0) {
    const formattedQty = formatSellableQuantity(displayQuantity);
    messagingSuccess = `${formattedQty} `;
    messagingPrimary = 'eligible for';
    messagingFree = ' Free ';
    messagingBOSS = 'Ship to Store';
  }

  if (isFunction(customRender)) {
    return customRender(
      { pickUpTemplate: PICKUP_TEMPLATES.BOSS,
        icon: InStockIcon,
        values: { messagingSuccess, messagingPrimary, displayQuantity } });
  }

  return (
    <PodMessage
      messageIcon={InStockIcon}
      messageType="Pickup"
    >
      <>
        <span className="sui-text-success">{messagingSuccess}</span>
        <span className="sui-text-success">
          <span className={messagingBOSS === '' ? '' : 'sui-text-primary'}>{messagingPrimary}</span>
          {messagingFree}
          {messagingBOSS}
        </span>
      </>
    </PodMessage>
  );
};

BOSS.propTypes = {
  displayQuantity: PropTypes.number,
  customRender: PropTypes.func
};

BOSS.defaultProps = {
  displayQuantity: 0,
  customRender: null
};
