import React, { useMemo } from 'react';
import { extend } from '@thd-nucleus/data-sources';
import {
  ProductPod,
  ProductRating,
  ProductImage,
  PodSection
} from '@thd-olt-component-react/product-pod';
import { Price } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { createTheme, ThemeProvider } from '@one-thd/sui-atomic-components';
import {
  string, number
} from 'prop-types';

const CustomProductPod = ({
  itemId, productIndex, storeId, scheme, theme
}) => {
  const analyticsData = {
    parent: 'recs-product-pod-carousel',
    position: productIndex,
    scheme
  };

  const merchRec = useMemo(() => {
    return `MERCH=REC-_-${scheme}-_-n/a-_-${productIndex}-_-n/a-_-n/a-_-n/a-_-n/a-_-n/a`;
  }, [scheme]);

  const productRatingTheme = createTheme({
    palette: {
      mode: 'light',
      text: {
        primary: 'inverse',
        subtle: '191 191 191'
      }
    }
  });

  return (
    <div className="sui-bg-primary sui-text-primary">
      <ProductPod
        itemId={itemId}
        analyticsData={analyticsData}
        storeId={storeId}
        gap="sui-gap-0"
        padding="sui-p-0"
        render={(pod) => (
          <>
            <ProductImage
              itemId={pod.itemId}
              showSecondaryImage={pod.showSecondaryImage}
              onClick={() => {}}
              merchQueryParam={merchRec}
            />
            <PodSection columnAlign alignTop gap="sui-gap-0">
              <ProductHeader
                brand="inline"
                itemId={pod.itemId}
                brandTitleMaxLine={2}
                merchQueryParam={merchRec}
              />
              {theme === 'EndcapBFTheme'
                ? (
                  <ThemeProvider theme={productRatingTheme}>
                    <ProductRating itemId={pod.itemId} merchQueryParam={merchRec} />
                  </ThemeProvider>
                )
                : (<ProductRating itemId={pod.itemId} merchQueryParam={merchRec} />)}

              <div className="sui-mt-4">
                <Price
                  itemId={pod.itemId}
                  large={false}
                  storeId={pod.storeId}
                  displayEachUom={false}
                  onSavingsCenterToggle={pod.onSavingsCenterToggle}
                  hideForProductTypes={['HDQC']}
                  stackWasPrice
                  displayDarkTheme={theme === 'EndcapBFTheme'}
                />
              </div>
            </PodSection>
          </>
        )}
      />
    </div>
  );
};

CustomProductPod.propTypes = {
  itemId: string,
  productIndex: number,
  storeId: string,
  scheme: string,
  theme: string
};

CustomProductPod.defaultProps = {
  itemId: '',
  productIndex: 1,
  storeId: '8119',
  scheme: '',
  theme: ''
};

CustomProductPod.dataModel = extend(
  ProductPod,
  ProductImage,
  Price,
  ProductRating,
  ProductHeader
);

export { CustomProductPod };