import {
  getApiMockParam,
  getCustomerType,
  getEventFilterIds,
  getOrganicSkus,
  getViewedSku,
  setAppId
} from './queryParamsHelpers';
import { BANNER, adServerClients } from '../constants/constants';
import { isServer } from './helpers';

export const getBannerQueryParams = (requestConfig) => {
  const queryParams = {};
  // first build common field for all banner types
  queryParams.schema = requestConfig.schema;
  queryParams.appid = setAppId(requestConfig);
  queryParams.adServer = requestConfig.adServer;
  const browserId = requestConfig?.browserId;
  if (browserId) {
    queryParams.browserid = browserId;
  }
  queryParams.customerType = (requestConfig.customerType) ? requestConfig.customerType : 'b2c';
  const bannerType = requestConfig.bannerType;
  if (bannerType) {
    queryParams.bannerType = bannerType;
  }
  const appName = window?.experienceMetadata?.name;
  if (appName) {
    queryParams.appName = appName;
  }
  if (!requestConfig.ignoreStoreId) {
    const storeId = requestConfig?.experienceContext?.useStoreId || requestConfig?.experienceContext?.store?.storeId;
    if (storeId) {
      queryParams.storeId = storeId;
    } else {
      queryParams.storeId = 8119;
    }
  }

  // utilizeRvData param is being passed from exp(my-homepage, local-ad) which set to true,
  if (requestConfig?.utilizeRvData) {
    const customerIdentifier = !isServer() && requestConfig.guestUserID;
    if (customerIdentifier) {
      queryParams.customerIdentifier = customerIdentifier;
    }
  }

  if (requestConfig?.heroBannerPosition) {
    queryParams.bannerPosition = requestConfig.heroBannerPosition;
  }

  return queryParams;

};

export const getQueryParams = (requestConfig) => {
  const queryParams = {};

  queryParams.schema = requestConfig.schema;
  queryParams.appid = setAppId(requestConfig);

  if (requestConfig.adServer) {
    queryParams.adServer = requestConfig.adServer;
  }

  const browserid = requestConfig?.browserId;

  if (browserid) {
    queryParams.browserid = browserid;
  }

  // existing implementation
  const category = requestConfig?.pageContext?.data?.searchModel?.metadata?.categoryID;
  if (category) {
    queryParams.category = category;
  }

  const nValue = getEventFilterIds(requestConfig.pageContext);
  if (nValue) {
    queryParams.nValue = nValue;
  }

  if (!category && !nValue) {
    // if category doesn't exist from pagecontext
    // we need to send slugId
    if (requestConfig?.slugId) {
      queryParams.nValue = requestConfig.slugId;
    }
  }

  const viewedsku = getViewedSku(requestConfig);
  if (viewedsku) {
    queryParams.viewedsku = viewedsku;
  }

  const customerType = getCustomerType(requestConfig);
  if (customerType) {
    queryParams.customerType = customerType;
  }

  const mock = getApiMockParam();
  if (mock) {
    queryParams.mock = mock;
  }

  const bannerType = requestConfig.bannerType;
  if (bannerType) {
    queryParams.bannerType = bannerType;
  }

  if (requestConfig.carouselType) {
    queryParams.carouselType = requestConfig.carouselType;
  }

  /*
   * Note: ternary operation needs to check on carouselType
   * because standard banner has no bannerType, so we need to
   * effectively use show=banner as the default.
   */

  // product, banner
  queryParams.show = requestConfig?.show
    ? requestConfig?.show
    : BANNER;

  const appName = window?.experienceMetadata?.name;
  if (appName) {
    queryParams.appName = appName;
  }

  if (!requestConfig.ignoreStoreId) {
    const storeid = requestConfig?.experienceContext?.useStoreId || requestConfig?.experienceContext?.store?.storeId;
    if (storeid) {
      queryParams.storeid = storeid;
    }
  }

  const keyword = requestConfig?.pageContext?.keyword;
  if (keyword) {
    queryParams.keyword = keyword;
  }

  const organicskus = getOrganicSkus(requestConfig);
  if (organicskus) {
    if (requestConfig.callBannerApi) {
      queryParams.matchProducts = organicskus;
    } else {
      queryParams.organicskus = organicskus;
    }
  }

  /*
     "utilizeRvData" param is being passed from exp(my-homepage, local-ad) which set to true,
        for other exp, customerIdentifier requestParam is not passed in request payload
     If "utilizeRvData" is true, in the above "getOrganicSkus" func, we check in localStorage whether any skus exists or not
     if any skus exist in localStorage and currentTime does not exceed the refresh time, we pass these organicSkus instead of customerIdentifier
  */
  const customerIdentifier = requestConfig.guestUserID;
  if (requestConfig.utilizeRvData && !organicskus) {
    queryParams.customerIdentifier = customerIdentifier;
  }

  if (requestConfig.abTestGroup) {
    queryParams.abTestGroup = requestConfig.abTestGroup;
  }
  return queryParams;
};
