import React from 'react';
import PropTypes from 'prop-types';
import helpers from '../../../helpers/price-helper';

export const StrikeThrough = ({ price }) => {
  const formattedPrice = helpers.getUSDollarWithCommas(price);
  return (
    <span className="sui-line-through sui-text-lg sui-text-subtle sui-leading-none sui-mb-2">
      {formattedPrice}
    </span>
  );
};

StrikeThrough.propTypes = {
  price: PropTypes.number,
};

StrikeThrough.defaultProps = {
  price: 0
};

StrikeThrough.displayName = 'StrikeThrough';
